import Swal from 'sweetalert2';
import Loading from '../components/Loading';
import React, { useState, useEffect } from 'react';
import axios from 'axios';  
import HomeTitle from '../components/Home/HomeTitle';
import HomeNoShop from '../components/Home/HomeNoShop';
import HomeCardLink from '../components/Home/HomeCardLink';
import HomeQRButton from '../components/Home/HomeQRButton';
import HomeQRModal from '../components/Home/HomeQRModal';
import './HomePage.css';
import {Col, Container, Row} from "react-bootstrap";

export default function HomePage(){

    const keyLocalStorage = 'authToken';
    
    const [isFillShopData, setIsFillShopData] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingModules, setIsLoadingModules] = useState(true);
    const [qr, setQr] = useState();

    const [shop, setShop] = useState();
    const [modules, setModules] = useState([]);
    const [moduleConfigs, setModuleConfigs] = useState([]);

    const [isDelivery, setIsDelivery] = useState(false);
    const [isPedido, setIsPedido] = useState(false);
    const [firstRun, setFirstRun] = useState(false);
    const [priceDelivery, setPriceDelivery] = useState(0);

    useEffect(() => {

        document.title = 'QReate';

        axios.get(process.env.REACT_APP_API_SHOP_ROUTE + '/1', {
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            }
        })
        .then((response) => {
            setIsFillShopData(true);
            setShop(response.data);
            getAllModules();
            getAllModuleConfigs();
        })
        .catch(() => {
            setIsFillShopData(false)
        })
        .finally(() => {
            setIsLoading(false)
        });

    }, []);

    setTimeout(function () {
        if (!firstRun) {
            setIsDelivery(getValueByCode("isDelivery") == "true");
            setIsPedido(getValueByCode("isOrderActive") == "true");
            setPriceDelivery( getValueByCode("priceDeliveryRange1"));
            setIsLoadingModules(false);
            setFirstRun(true);
        }
    }, 2000);

    const getAllModules = () => {
        axios.get(process.env.REACT_APP_API_MODULES_ROUTE, {
            withCredentials: true
        })
            .then(response => {
                setModules(response.data);
            })
            .catch(error => {
                console.error(error)});
    }

    const getAllModuleConfigs = () => {
        axios.get(process.env.REACT_APP_API_MODULES_CONFIGS_ROUTE + "1", {
            withCredentials: true
        })
            .then(response => {
                setModuleConfigs(response.data);
            })
            .catch(error => {
                console.error(error)});
    }

    function getValueByCode(code) {
        const moduleId = modules.find(module => module.code === code)?.id;
        if (moduleId === undefined) {
            return null;
        }

        const config = moduleConfigs.find(config => config.moduleConfigId === moduleId);
        if (config) {
            return config.value;
        } else {
            return null;
        }
    }

    const setValueConfig = (value, config) => {
        const queryParams = {
            [config]: value.toString()
        };
        axios.put(process.env.REACT_APP_API_MODULES_CONFIGS_SET_ROUTE + 1, null,{
            params: queryParams,
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            }
        })
        .then(() => {
            getAllModules();
            getAllModuleConfigs();
        })
        .catch(() => { console.log("error") });
    }

    const generateQR = () => {
        axios.get(process.env.REACT_APP_API_GENERATE_QR_ROUTE, {
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            },
            responseType: 'arraybuffer'
        })
        .then(response => {
            const blob = new Blob([response.data], { type: 'image/png' });
            const imageUrl = URL.createObjectURL(blob);
            // Decodificar la URL
            const decodedUrl = decodeURIComponent(imageUrl);
            setQr(decodedUrl);
        })
        .catch(error => {
            alertError();
            console.error(error)
        });
    }

    function alertError(){
        
        Swal.fire({
          title: 'Upsss',
          text: 'Ocurrió un error, vuelve a intentar',
          icon: 'error',
          timer: 1500,
          confirmButtonColor: 'rgb(246, 139, 127)',
          showConfirmButton: false,
            title: 'Upsss',
            text: 'Ocurrió un error, vuelve a intentar',
            icon: 'error',
            timer: 1500,
            showConfirmButton: false,
        });

    }

    const download = () => {
        const url = qr;
        const a = document.createElement('a');
        a.href = url;
        a.download = 'qr_code.png';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    if (isLoading) {
        return <Loading/>; // Muestra un mensaje de carga mientras se realiza la petición
    }

    return (
            <div className='homeContainer'>
                <HomeTitle />
                { !isFillShopData ? 
                    (
                        <HomeNoShop /> 
                    ) : (
                        <>
                            <div className='cardListContainer'>
                                <HomeCardLink to={'/carta'} title={'CARTA'} subtitle={'¡Aca podés ver tu carta lista!'} />
                                <HomeQRButton action={generateQR} title={'QR'} subtitle={'Descargá el QR listo para usar'} />
                                {isLoadingModules ? (
                                    <div className="loader-container-home-modules">
                                        <div className="loader-home-modules"></div>
                                    </div>
                                ) : (
                                <Row className="mt-5 display-flex" xs={1} xl={3}>
                                    <Col className="col-xl-2">
                                        <label className="buttonIsDelivery">
                                            <h2 className="btnIsDelivery">Pedidos</h2>
                                            <input type="checkbox" id="isPedido" className="switch isDelivery" checked={isPedido}
                                                   onChange={(e) => {
                                                       setValueConfig(!isPedido, "isOrderActive");
                                                       setIsPedido(!isPedido);
                                                   }} />
                                        </label>
                                    </Col>
                                    <Col className="col-xl-2">
                                        <label className="buttonIsDelivery">
                                            <h2 className="btnIsDelivery">Delivery</h2>
                                            <input type="checkbox" className="switch isDelivery" checked={isDelivery}
                                                   onChange={(e) => {
                                                       setValueConfig(!isDelivery, "isDelivery");
                                                       setIsDelivery(!isDelivery);
                                                   }} />
                                        </label>
                                    </Col>
                                    <Col className="col-xl-3">
                                        <label>
                                            <h2 className="btnIsDelivery">Precio del envío</h2>
                                            <input type="number" className="form-control priceDelivery" value={priceDelivery}
                                                   onChange={(e) => {
                                                       setValueConfig(e.target.value, "priceDeliveryRange1");
                                                       setPriceDelivery(e.target.value);
                                                   }} />
                                        </label>
                                    </Col>
                                </Row>)}

                            </div>
                        </>
                    )
                }
                <HomeQRModal qr={qr} download={download}/>
            </div>
        );
}