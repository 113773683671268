import React, { useEffect, useState } from "react";
import Swal from 'sweetalert2';
import axios from 'axios'; 
import Loading from "../components/Loading";
import Order from "../components/Order";
import {Col, Container, Nav, Row, Table} from "react-bootstrap";

export default function Orders() {
  const keyLocalStorage = 'authToken';

  const [orders, setOrders] = useState([]);
  const [ordersPag, setOrdersPag] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [isConfirmed, setIsConfirmed] = useState(true);
  const [isCanceled, setIsCanceled] = useState(false);

  const [actualPage, setActualPage] = useState(1);
  const productsPerPage = 10;
  let indexOfLastProduct = actualPage * productsPerPage;
  let indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  useEffect(() => {
    document.title = 'QReate - Pedidos';
    if(localStorage.getItem(keyLocalStorage) != null){
      getAllOrders("COMPLETED");
    }
  }, []);

  const getAllOrders = (status) => {
    const queryParams = new URLSearchParams();
    queryParams.append('status', status);
    
    axios.get(`${process.env.REACT_APP_API_ORDER_WHATSAPP_ROUTE}?${queryParams.toString()}`, {
      withCredentials: true,
      headers: {
        Authorization: localStorage.getItem(keyLocalStorage),
      }
    })
    .then(response => {
        indexOfLastProduct = 1 * productsPerPage;
        indexOfFirstProduct = indexOfLastProduct - productsPerPage;

        setOrders(response.data);
        setOrdersPag(response.data.slice(indexOfFirstProduct, indexOfLastProduct));
        setActualPage(1);
      })
      .catch(error => {
        alertError();
        console.error(error)})
      .finally(() => {
        setIsLoading(false);
      });
  }

  function alertError(){
    Swal.fire({
      title: 'Upsss',
      text: 'Ocurrió un error, vuelve a intentar',
      icon: 'error',
      timer: 1500,
      showConfirmButton: false,
    });
  }

  function formatPrice(price) {
    if (!price) return ''; // Manejar caso de valor vacío
    const parts = price.toString().split('.'); // Separar parte entera de la decimal
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agregar separador de miles
    return parts.join(','); // Unir las partes con coma como separador decimal
  }

  const previewPage = () => {
    if(actualPage != 1){
      setActualPage(actualPage - 1);
      indexOfLastProduct = (actualPage - 1) * productsPerPage;
      indexOfFirstProduct = indexOfLastProduct - productsPerPage;
      setOrdersPag(orders.slice(indexOfFirstProduct, indexOfLastProduct));
    }
  }

  const nextPage = () => {
    if(orders.slice((((actualPage + 1) * productsPerPage) - productsPerPage), ((actualPage + 1) * productsPerPage)).length > 0){
      setActualPage(actualPage + 1);
      indexOfLastProduct = (actualPage + 1) * productsPerPage;
      indexOfFirstProduct = indexOfLastProduct - productsPerPage;
      setOrdersPag(orders.slice(indexOfFirstProduct, indexOfLastProduct));
    }
  }

  const hasMoreOrders = (actualPageParam) =>{
    if (orders && orders.length > 0 &&
      orders.slice((((actualPageParam + 1) * productsPerPage) - productsPerPage), ((actualPageParam + 1) * productsPerPage)).length > 0) {
      return true;
    }
    return false;
  }

  if (isLoading) {
      return <Loading/>;
  }

    return (
      <div className="containersOrders">
        <Container className="mt-3">
          <Row>
            <Col>
              <h1 className="titleOrdersWhatsapp">Pedidos</h1>
            </Col>
          </Row>
        </Container>

        <Container className="divConfirmAndCancel">
            <Row className={isConfirmed ? "divConfirms fondoYletraBlanca" : "divConfirms"} onClick={(e) => {setIsLoading(true); setIsConfirmed(true); setIsCanceled(false); getAllOrders("COMPLETED");}}>
              Confirmados
            </Row>
            <Row className={isCanceled ? "divCancels fondoYletraBlanca" : "divCancels"} onClick={() => {setIsLoading(true); setIsCanceled(true); setIsConfirmed(false); getAllOrders("CANCELLED");}}>
              Cancelados
            </Row>
        </Container>

        <div className="m-2">
          <Table responsive className="table-striped">
            <thead className="tableHead">
            <tr>
              <th>ID</th>
              <th>Número de pedido</th>
              <th>Fecha y hora</th>
              <th>Nombre y apellido</th>
              <th>Estado</th>
              <th>Total</th>
              <th>Delivery</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
              {(ordersPag != '' && ordersPag != [] && ordersPag != null) ? (
                  ordersPag.map(order => (
                      <Order order={order} getAllOrders={getAllOrders}/>
                  ))
              ) : (
                  <tr>
                    <td colSpan="7" className="backgroundTable text-center">Aún no hay Pedidos</td>
                  </tr>
              )}
            </tbody>
          </Table>

            <Nav aria-label="Page navigation example" className="pb-2">
              <ul className="pagination">
                <li className="page-item">
                  <a className="page-link colorPrincipal" href="#" aria-label="Previous" onClick={() => {previewPage()}}>
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                {actualPage > 1 && <li className="page-item"><a className="page-link colorPrincipal">{actualPage - 1}</a></li>}
                <li className="page-item"><a className="page-link colorPrincipal" style={{backgroundColor: 'rgb(255 230 230)'}}>{actualPage}</a></li>
                {hasMoreOrders(actualPage) && <li className="page-item"><a className="page-link colorPrincipal">{actualPage + 1}</a></li>}
                <li className="page-item">
                  <a className="page-link colorPrincipal" href="#" aria-label="Next" onClick={() => {nextPage()}}>
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </Nav>

        </div>
      </div>
    );
  }