import React, {useState} from 'react';
import {PiInfo} from "react-icons/pi";
import Loading from "../components/Loading";
import axios from 'axios';
import Swal from 'sweetalert2';
import {Modal} from "react-bootstrap";

const Order = ({order, getAllOrders}) => {

  const keyLocalStorage = 'authToken';

  const [isLoading, setIsLoading] = useState(true);

  const [orderDetails, setOrdersDetails] = useState([]);
  const [orderAddress, setOrdersAddress] = useState(null);

  const [reasonCancellation, setReasonCancellation] = useState("");

  const [showModalDetails, setShowModalDetails] = useState(false);
  const [showModalAddress, setShowModalAddress] = useState(false);
  const [showModalCancell, setShowModalCancell] = useState(false);

  const handleCloseModal = () => {
    setShowModalDetails(false);
  };

  const findOrdersDetails = () => {
    axios.get(process.env.REACT_APP_API_ORDER_DETAILS_ROUTE + order.orderId, {
      withCredentials: true,
      headers: {
        Authorization: localStorage.getItem(keyLocalStorage),
      }
    })
    .then(response => {
        setOrdersDetails(response.data);
      })
      .catch(() => {
        alertError();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const findAddressOrder = () => {
    axios.get(process.env.REACT_APP_API_ORDER_WHATSAPP_ADDRESS_ROUTE + order.orderId, {
      withCredentials: true,
      headers: {
        Authorization: localStorage.getItem(keyLocalStorage),
      }
    })
    .then(response => {
        setOrdersAddress(response.data);
      })
      .catch(() => {
        alertError();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const cancelOrder = () => {
    Swal.fire({
      title: '¿Estás seguro de cancelar el pedido?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      confirmButtonColor: 'rgb(246, 139, 127)',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        axios.put(process.env.REACT_APP_API_ORDER_CANCEL_ROUTE + order.orderId, null, {
          params: {
            cancelDescription: reasonCancellation
          },
          withCredentials: true,
          headers: {
            Authorization: localStorage.getItem(keyLocalStorage),
          },
        })
        .then(() => {
            Swal.fire({
              icon: 'success',
              title: '¡Éxito!',
              text: 'Eliminado correctamente.',
              timer: 1500,
              showConfirmButton: false,
            });
            setReasonCancellation("");
            setShowModalCancell(false);
            getAllOrders('COMPLETED');
        })
        .catch(() => {
          alertError();
        })
        .finally(() => {
          setIsLoading(false);
        });
      }
    });
  }

  function alertError(){
    Swal.fire({
      title: 'Upsss',
      text: 'Ocurrió un error, vuelve a intentar',
      icon: 'error',
      timer: 1500,
      showConfirmButton: false,
    });
  }

  function formatPrice(price) {
    if (!price) return ''; // Manejar caso de valor vacío
    const parts = price.toString().split('.'); // Separar parte entera de la decimal
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agregar separador de miles
    return parts.join(','); // Unir las partes con coma como separador decimal
  }

  return (
    <>

      <tr key={order.id}>
        <td className="text-alig-center">{order.orderId}</td>
        <td className="text-alig-center">{order.orderNumber}</td>
        <td className="text-alig-center">{order.orderDate}</td>
        <td className="text-alig-center">{order.ownerOrder}</td>
        <td className="text-alig-center">{order.status === "COMPLETED" ? "Confirmado" : "Cancelado"}</td>
        <td className="text-alig-center">${formatPrice(order.totalAmount + (order.amountDelivery != null ? order.amountDelivery : 0))}</td>
        <td className="text-alig-center">
          {(order.delivery) ? (
              <>
                SI
                <button type="button" className="btn" onClick={() => {
                  findAddressOrder();
                  setIsLoading(true);
                  setShowModalAddress(true)
                }}>
                  <PiInfo className="" size="24" style={{color: '#2658cb'}}/>
                </button>
              </>
          ) : "NO"}
        </td>
        <td>
          <div className='divBtnsActionsOrder'>
            <button type="button" className="btn btn-success" onClick={() => {
              findOrdersDetails();
              setIsLoading(true)
              setShowModalDetails(true)
            }}>
              Detalle
            </button>
            {order.status !== "CANCELLED" &&
                <button type="button" className="btn btn-danger"
                onClick={() => setShowModalCancell(true)}>
                  Cancelar
                </button>
            }
          </div>
        </td>
      </tr>

        <Modal size="xl" show={showModalDetails} onHide={handleCloseModal}>
          <Modal.Header className="modal-header modalPedido">
            <h3 className="modal-title fs-5 titleModalOrderDetails" id="modalOrderDetailsLabel" style={{marginTop: '3%'}}>Detalles del pedido #{order.orderId}</h3>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModalDetails(false)}></button>
          </Modal.Header>

          <Modal.Body className="modal-body bodyModalOrderDetails">

              {isLoading ? (
                <Loading/>
              ) : (
                <>
                  {orderDetails.map(detail => (
                    <div className='detail' key={detail.id}>
                      <p>{detail.productName + " (id: " + detail.productId + ")"}</p>
                      <div style={{ display: 'flex', justifyContent: 'space-between', width: '30%' }}>
                        <p>X{detail.quantity}</p>
                        <p>${formatPrice(detail.subTotal)}</p>
                      </div>
                    </div>
                  ))}
                  {order.delivery &&
                      <div className='detail'>
                        <p>ENVÍO</p>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '30%' }}>
                          <p></p>
                          <p>${formatPrice(order.amountDelivery)}</p>
                        </div>
                      </div>
                  }
                <br/>

                <p style={{textAlign: 'end'}}><strong>TOTAL:</strong> ${formatPrice((order.totalAmount + (order.amountDelivery != null ? order.amountDelivery : 0)))}</p>

                <label htmlFor="observation" className="col-form-label">Observaciones:</label>
                <input type="text" className="form-control" value={order.orderObservation} disabled/>

                {order.status === "CANCELLED" &&
                <>
                  <label htmlFor="cancelationReason" className="col-form-label">Motivo de cancelación:</label>
                  <input type="text" className="form-control" value={order.cancellObservation} disabled/>
                </>
                }

                </>
              )}

          </Modal.Body>

          <Modal.Footer className="modal-footer modalPedido">
            <button id="btnCloseModalAdd" type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setShowModalDetails(false)}>Cerrar</button>
          </Modal.Footer>
        </Modal>

        <Modal size="xl" show={showModalCancell} onHide={() => setShowModalCancell(false)}>
          <Modal.Header className="modal-header modalPedido">
            <h3 className="modal-title fs-5 titleModalOrderDetails" id="modalCancelOrderLabel" style={{marginTop: '3%'}}>Cancelar pedido #{order.orderId}</h3>
            <button type="button" id="btnCloseModalCancelIcon" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {setReasonCancellation(""); setShowModalCancell(false)}}></button>
          </Modal.Header>

          <Modal.Body className="modal-body bodyModalCancelOrder">
            <div className="form-floating mb-3">
              <input type="text" className="form-control" id="canceledText" placeholder="Cancelado por ..." value={reasonCancellation} onChange={(e) => {setReasonCancellation(e.target.value)}}/>
              <label htmlFor="floatingInput">Motivo de cancelación</label>
            </div>
          </Modal.Body>

          <Modal.Footer className="modal-footer modalPedido">
            <button id={"btnCloseModalCancel" + order.orderId} type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => {setReasonCancellation(""); setShowModalCancell(false)}}>Cerrar</button>
            <button type="button" className="btn btn-primary" onClick={cancelOrder}>Cancelar pedido</button>
          </Modal.Footer>
        </Modal>

        <Modal size="xl" show={showModalAddress} onHide={() => setShowModalAddress(false)}>
          <Modal.Header className="modal-header modalPedido">
            <h3 className="modal-title fs-5 titleModalOrderDetails" id="modalAddressOrderLabel" style={{marginTop: '3%'}}>Domicilio de entrega del pedido #{order.orderId}</h3>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
              setShowModalAddress(false);
            }}></button>
          </Modal.Header>

          <Modal.Body className="modal-body bodyModalAddressOrder">
            {orderAddress != null ? (
              <>
              <div className='row'>
              <div className='col'>
                <label htmlFor="stateAddress" className="col-form-label">Calle:</label>
                <input type="text" className="form-control" value={orderAddress.adress} disabled/>
              </div>
              <div className='col'>
                <label htmlFor="stateAddress" className="col-form-label">Nro:</label>
                <input type="text" className="form-control" value={orderAddress.numberAdress} disabled/>
              </div>
            </div>

            <div className='row'>
              <div className='col'>
                <label htmlFor="stateAddress" className="col-form-label">Piso:</label>
                <input type="text" className="form-control" value={orderAddress.floor} disabled/>
              </div>
              <div className='col'>
                <label htmlFor="stateAddress" className="col-form-label">Depto:</label>
                <input type="text" className="form-control" value={orderAddress.department} disabled/>
              </div>
            </div>

            <div className='row'>
              <div className='col'>
                <label htmlFor="stateAddress" className="col-form-label">Indicaciones del domicilio:</label>
                <input type="text" className="form-control" value={orderAddress.deliveryObservation} disabled/>
              </div>
            </div>
              </>
            ) :
            (<></>)}
          </Modal.Body>

          <Modal.Footer className="modal-footer modalPedido">
            <button id="btnCloseModalAdd" type="button" className="btn btn-secondary" onClick={() => setShowModalAddress(false)}>Cerrar</button>
          </Modal.Footer>
        </Modal>
    </>
    
  );
};

export default Order;