import React, {useState, useEffect} from 'react';
import {RequestQuote, TrashCan, Search, View, ViewOff, CloseLarge} from '@carbon/icons-react';
import Swal from 'sweetalert2';
import axios from 'axios';
import * as AxiosService from '../services/AxiosService';
import * as ConversorService from '../services/ConversorService';
import * as DownloadService from '../services/DownloadService';
import {Button, Col, Container, InputGroup, Row, Table} from "react-bootstrap";

export default function MisProductos() {
    const keyLocalStorage = 'authToken';

    //Lista de todos los productos
    const [products, setProducts] = useState([]);

    //Lista de todas las categorias
    const [categories, setCategories] = useState([]);
    //list categorias activas
    const [categoriesActive, setCategoriesActive] = useState([]);

    //Lista de productos filtrados
    const [searchProducts, setSearchProducts] = useState([]);
    const [searching, setSearching] = useState(false);

    const [nameSearching, setNameSearching] = useState('');
    const [idCatSearching, setIdCatSearching] = useState('');

    const [productName, setProductName] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');

    const [productIdEdit, setProductIdEdit] = useState('');
    const [productNameEdit, setProductNameEdit] = useState('');
    const [productDescriptionEdit, setProductDescriptionEdit] = useState('');
    const [productPriceEdit, setProductPriceEdit] = useState('');
    const [selectedCategoryEdit, setSelectedCategoryEdit] = useState('');
    const [productStatusEdit, setProductStatusEdit] = useState('');

    const [fileMassiveProducts, setFileMassiveProducts] = useState(null);
    const [fileNameMassiveProducts, setFileNameMassiveProducts] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    const [actualPage, setActualPage] = useState(1);
    const productsPerPage = 10;
    let indexOfLastProduct = actualPage * productsPerPage;
    let indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    let currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

    const [cantProductsFiltered, setCantProductsFiltered] = useState()

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const handleSelectCategory = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleSelectCategoryEdit = (event) => {
        setSelectedCategoryEdit(event.target.value);
    };

    useEffect(() => {
        document.title = 'QReate - Productos';
        if (localStorage.getItem(keyLocalStorage) != null) {
            getAllProducts();
            getAllCategories();
        }
    }, []);

    const saveProduct = () => {
        if (productName == '' || productName == null || selectedCategory == '' || selectedCategory == null || productPrice == '' || productPrice == null) {
            if (productName == '' || productName == null) {
                let fieldRequiredElement = document.querySelector('.fieldRequiredName');
                fieldRequiredElement.style.display = 'block';
                let inputElement = document.getElementById('nombre');
                inputElement.style.border = '1px solid #ff1717';
            }
            if (selectedCategory == '' || selectedCategory == null) {
                let fieldRequiredElement = document.querySelector('.fieldRequiredCategory');
                fieldRequiredElement.style.display = 'block';
                let inputElement = document.getElementById('selector');
                inputElement.style.border = '1px solid #ff1717';
            }
            if (productPrice == '' || productPrice == null) {
                let fieldRequiredElement = document.querySelector('.fieldRequiredPrice');
                fieldRequiredElement.style.display = 'block';
                let inputElement = document.getElementById('price');
                inputElement.style.border = '1px solid #ff1717';
            }
        } else {
            let newProduct = {
                name: productName,
                description: productDescription,
                price: productPrice,
                idCategory: selectedCategory
            }

            axios.post(process.env.REACT_APP_API_PRODUCTS_ROUTE, newProduct, {
                withCredentials: true,
                headers: {
                    Authorization: localStorage.getItem(keyLocalStorage),
                }
            })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Guardada correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                    });
                    const closeButton = document.getElementById('btnCloseModalAdd');
                    closeButton.click();
                    getAllProducts();
                })
                .catch(error => {
                    alertError();
                    console.error(error)
                });

            setProductName('');
            setProductDescription('');
            setSelectedCategory('');
            setProductPrice('');
        }
    }

    const getAllProducts = () => {
        axios.get(process.env.REACT_APP_API_PRODUCTS_ROUTE, {
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            }
        })
            .then(response => {
                setProducts(response.data);
            })
            .catch(error => {
                alertError();
                console.error(error)
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    function alertError() {
        Swal.fire({
            title: 'Upsss',
            text: 'Ocurrió un error, vuelve a intentar',
            icon: 'error',
            timer: 1500,
            showConfirmButton: false,
        });
    }

    function alertErrorConMensaje(mensaje) {
        Swal.fire({
            title: 'Error',
            text: mensaje,
            icon: 'error',
            showConfirmButton: true,
            confirmButtonColor: 'rgb(246, 139, 127)',
            allowOutsideClick: false,  // Evita que se cierre haciendo clic fuera del mensaje
            allowEscapeKey: false,  // Evita que se cierre pulsando la tecla Esc
            allowEnterKey: false  // Evita que se cierre pulsando la tecla Enter
        });
    }

    const deleteProduct = (product) => {
        Swal.fire({
            title: '¿Estás seguro de eliminar el producto?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            confirmButtonColor: 'rgb(246, 139, 127)',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(process.env.REACT_APP_API_PRODUCTS_ROUTE + '/' + product.id, {
                    withCredentials: true,
                    headers: {
                        Authorization: localStorage.getItem(keyLocalStorage),
                    }
                })
                    .then(response => {
                        Swal.fire({
                            icon: 'success',
                            title: '¡Éxito!',
                            text: 'Eliminado correctamente.',
                            timer: 1500,
                            showConfirmButton: false,
                        });
                        getAllProducts();
                    })
                    .catch(error => {
                        alertError();
                        console.error('Error al realizar la solicitud PUT', error);
                    });
            }
        });
    }

    const editProduct = (product) => {
        setProductIdEdit(product.id);
        setProductNameEdit(product.name);
        setProductDescriptionEdit(product.description);
        setProductPriceEdit(product.price);
        setSelectedCategoryEdit(product.idCategory);
        setProductStatusEdit(product.status);
    }

    const saveEditproduct = () => {
        if (productNameEdit == '' || productPriceEdit == '' || selectedCategoryEdit == '') {
            if (productNameEdit == '' || productNameEdit == null) {
                let fieldRequiredElement = document.querySelector('.fieldRequiredNameEdit');
                fieldRequiredElement.style.display = 'block';
                let inputElement = document.getElementById('nombreEdit');
                inputElement.style.border = '1px solid #ff1717';
            }
            if (selectedCategoryEdit == '' || selectedCategoryEdit == null) {
                let fieldRequiredElement = document.querySelector('.fieldRequiredCategoryEdit');
                fieldRequiredElement.style.display = 'block';
                let inputElement = document.getElementById('selectorEdit');
                inputElement.style.border = '1px solid #ff1717';
            }
            if (productPriceEdit == '' || productPriceEdit == null) {
                let fieldRequiredElement = document.querySelector('.fieldRequiredPriceEdit');
                fieldRequiredElement.style.display = 'block';
                let inputElement = document.getElementById('priceEdit');
                inputElement.style.border = '1px solid #ff1717';
            }
        } else {
            let editProduct = {
                name: productNameEdit,
                description: productDescriptionEdit,
                price: productPriceEdit,
                idCategory: selectedCategoryEdit,
                status: productStatusEdit
            }

            axios.put(process.env.REACT_APP_API_PRODUCTS_ROUTE + '/' + productIdEdit, editProduct, {
                withCredentials: true,
                headers: {
                    Authorization: localStorage.getItem(keyLocalStorage),
                }
            })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Editada correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                    });
                    getAllProducts();
                    if (searching) {
                        setTimeout(() => {
                            const reloadSearch = document.getElementById('btnReloadSearchProduct');
                            reloadSearch.click();
                        }, 1500);
                    }
                })
                .catch(error => {
                    alertError();
                    console.error(error)
                });

            const closeButton = document.getElementById('btnCloseModalEdit');
            closeButton.click();

            setProductIdEdit('');
            setProductNameEdit('');
            setProductDescriptionEdit('');
            setProductPriceEdit('');
            setSelectedCategoryEdit('');
            setProductStatusEdit('');
        }
    }

    const getAllCategories = () => {
        axios.get(process.env.REACT_APP_API_CATEGORIES_ROUTE, {
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            }
        })
            .then(response => {
                setCategories(response.data);
                setCategoriesActive(response.data.filter((categoria) => categoria.status));
            })
            .catch(error => {
                alertError();
                console.error(error)
            });
    }

    const getCategoryNameById = (categoryId) => {
        const foundCategory = categories.find(category => category.id == categoryId);
        return foundCategory ? foundCategory.name : 'Categoría no encontrada';
    };

    const closeModelSave = () => {
        return (productName != '' && productDescription != '' && productPrice != '');
    }

    const closeModelEdit = () => {
        return (productNameEdit != '' && productDescriptionEdit != '' && productPriceEdit != '' && productNameEdit != null && productDescriptionEdit != null && productPriceEdit != null);
    }

    const resetValuesAndStyleErrors = () => {
        resetStyleErrorName();
        resetStyleErrorDesc();
        resetStyleErrorCat();
        resetStyleErrorPrice();

        setProductName('');
        setProductDescription('');
        setSelectedCategory('');
        setProductPrice('');
    }

    const resetStyleErrorsEdit = () => {
        resetStyleErrorNameEdit();
        resetStyleErrorDescEdit();
        resetStyleErrorCatEdit();
        resetStyleErrorPriceEdit();
    }

    const cleanErrorName = () => {
        if (productName != '' && productName != null) {
            resetStyleErrorName();
        }
    }

    const resetStyleErrorName = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredName');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('nombre');
        inputElement.style.border = '';
    }

    const cleanErrorNameEdit = () => {
        if (productNameEdit != '' && productNameEdit != null) {
            resetStyleErrorNameEdit();
        }
    }

    const resetStyleErrorNameEdit = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredNameEdit');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('nombreEdit');
        inputElement.style.border = '';
    }

    const cleanErrorDesc = () => {
        if (productDescription != '' && productDescription != null) {
            resetStyleErrorDesc();
        }
    }

    const resetStyleErrorDesc = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredDescription');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('description');
        inputElement.style.border = '';
    }

    const resetStyleErrorCat = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredCategory');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('selector');
        inputElement.style.border = '';
    }

    const cleanErrorDescEdit = () => {
        if (productDescriptionEdit != '' && productDescriptionEdit != null) {
            resetStyleErrorDescEdit();
        }
    }

    const resetStyleErrorDescEdit = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredDescriptionEdit');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('descriptionEdit');
        inputElement.style.border = '';
    }

    const resetStyleErrorCatEdit = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredCategoryEdit');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('selectorEdit');
        inputElement.style.border = '';
    }

    const cleanErrorPrice = () => {
        if (productPrice != '' && productPrice != null) {
            resetStyleErrorPrice();
        }
    }

    const resetStyleErrorPrice = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredPrice');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('price');
        inputElement.style.border = '';
    }

    const cleanErrorPriceEdit = () => {
        if (productPriceEdit != '' && productPriceEdit != null) {
            resetStyleErrorPriceEdit();
        }
    }

    const resetStyleErrorPriceEdit = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredPriceEdit');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('priceEdit');
        inputElement.style.border = '';
    }

    const search = () => {
        if (nameSearching != '' && idCatSearching != '') {
            setSearching(true);
            const productsFilters = products.filter(producto =>
                producto.name.toLowerCase().includes(nameSearching.toLowerCase())
                && producto.idCategory == idCatSearching.toString());

            setCantProductsFiltered(productsFilters.length);

            setActualPage(1);
            indexOfLastProduct = 1 * productsPerPage;
            indexOfFirstProduct = indexOfLastProduct - productsPerPage;
            setSearchProducts(productsFilters.slice(indexOfFirstProduct, indexOfLastProduct));
        } else if (nameSearching != '') {
            setSearching(true);
            const productsFilters = products.filter(producto =>
                producto.name.toLowerCase().includes(nameSearching.toLowerCase()));

            setCantProductsFiltered(productsFilters.length);

            setActualPage(1);
            indexOfLastProduct = 1 * productsPerPage;
            indexOfFirstProduct = indexOfLastProduct - productsPerPage;
            setSearchProducts(productsFilters.slice(indexOfFirstProduct, indexOfLastProduct));
        } else if (idCatSearching != '') {
            setSearching(true);
            const productsFilters = products.filter(producto =>
                producto.idCategory == idCatSearching.toString());

            setCantProductsFiltered(productsFilters.length);

            setActualPage(1);
            indexOfLastProduct = 1 * productsPerPage;
            indexOfFirstProduct = indexOfLastProduct - productsPerPage;
            setSearchProducts(productsFilters.slice(indexOfFirstProduct, indexOfLastProduct));
        } else {
            setSearching(false);
            setSearchProducts('');
            setActualPage(1);
            indexOfLastProduct = 1 * productsPerPage;
            indexOfFirstProduct = indexOfLastProduct - productsPerPage;
        }
    }

    const cleanSearch = () => {
        setIdCatSearching('');
        setNameSearching('');
        setSearching(false);
        setSearchProducts('');
        setActualPage(1);
        indexOfLastProduct = actualPage * productsPerPage;
        indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    }

    const isCategoryActive = (id) => {
        return categoriesActive.filter((categoria) => categoria.id == id).length > 0;
    };

    const enableProd = (product) => {
        if (product.idCategory != null && isCategoryActive(product.idCategory)) {
            let editProduct = {
                name: product.name,
                description: product.description,
                imgPath: product.imgPath,
                status: 1,
                price: product.price,
                idCategory: product.idCategory
            }

            axios.put(process.env.REACT_APP_API_PRODUCTS_ROUTE + '/' + product.id, editProduct, {
                withCredentials: true,
                headers: {
                    Authorization: localStorage.getItem(keyLocalStorage),
                }
            })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Activado correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                    });
                    getAllProducts();
                    if (searching) {
                        setTimeout(() => {
                            const reloadSearch = document.getElementById('btnReloadSearchProduct');
                            reloadSearch.click();
                        }, 1500);
                    }

                })
                .catch(error => {
                    alertError();
                    console.error(error)
                });
        } else {
            Swal.fire({
                title: 'Error',
                text: "No se puede ACTIVAR un producto sin CATEORIA O con CATEGORÍA INACTIVA",
                icon: 'error',
                showConfirmButton: true,
                confirmButtonColor: 'rgb(246, 139, 127)',
                allowOutsideClick: false,  // Evita que se cierre haciendo clic fuera del mensaje
                allowEscapeKey: false,  // Evita que se cierre pulsando la tecla Esc
                allowEnterKey: false  // Evita que se cierre pulsando la tecla Enter
            });
        }
    }

    const disableProd = (product) => {
        let editProduct = {
            name: product.name,
            description: product.description,
            imgPath: product.imgPath,
            status: 0,
            price: product.price,
            idCategory: product.idCategory
        }

        axios.put(process.env.REACT_APP_API_PRODUCTS_ROUTE + '/' + product.id, editProduct, {
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            }
        })
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Desactivado correctamente.',
                    timer: 1500,
                    showConfirmButton: false,
                });
                getAllProducts();
                if (searching) {
                    setTimeout(() => {
                        const reloadSearch = document.getElementById('btnReloadSearchProduct');
                        reloadSearch.click();
                    }, 1500);
                }
            })
            .catch(error => {
                alertError();
                console.error(error)
            });
    }

    function formatPrice(price) {
        if (!price) return ''; // Manejar caso de valor vacío
        const parts = price.toString().split('.'); // Separar parte entera de la decimal
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agregar separador de miles
        return parts.join(','); // Unir las partes con coma como separador decimal
    }

    const previewPage = () => {
        if (actualPage != 1) {
            if (searching) {
                let productsFilters;

                if (nameSearching != '' && idCatSearching != '') {
                    productsFilters = products.filter(producto =>
                        producto.name.toLowerCase().includes(nameSearching.toLowerCase())
                        && producto.idCategory == idCatSearching.toString());
                } else if (nameSearching != '') {
                    productsFilters = products.filter(producto =>
                        producto.name.toLowerCase().includes(nameSearching.toLowerCase()));
                } else if (idCatSearching != '') {
                    productsFilters = products.filter(producto =>
                        producto.idCategory == idCatSearching.toString());
                }

                setActualPage(actualPage - 1);
                indexOfLastProduct = (actualPage - 1) * productsPerPage;
                indexOfFirstProduct = indexOfLastProduct - productsPerPage;
                setSearchProducts(productsFilters.slice(indexOfFirstProduct, indexOfLastProduct));
            } else {
                setActualPage(actualPage - 1);
                indexOfLastProduct = (actualPage - 1) * productsPerPage;
                indexOfFirstProduct = indexOfLastProduct - productsPerPage;
                currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);
            }

        }
    }

    const nextPage = () => {
        if (searching) {
            let productsFilters;

            if (nameSearching != '' && idCatSearching != '') {
                productsFilters = products.filter(producto =>
                    producto.name.toLowerCase().includes(nameSearching.toLowerCase())
                    && producto.idCategory == idCatSearching.toString());
            } else if (nameSearching != '') {
                productsFilters = products.filter(producto =>
                    producto.name.toLowerCase().includes(nameSearching.toLowerCase()));
            } else if (idCatSearching != '') {
                productsFilters = products.filter(producto =>
                    producto.idCategory == idCatSearching.toString());
            }

            if (productsFilters.slice((((actualPage + 1) * productsPerPage) - productsPerPage), ((actualPage + 1) * productsPerPage)).length > 0) {
                setActualPage(actualPage + 1);
                indexOfLastProduct = (actualPage + 1) * productsPerPage;
                indexOfFirstProduct = indexOfLastProduct - productsPerPage;
                setSearchProducts(productsFilters.slice(indexOfFirstProduct, indexOfLastProduct));
            }
        } else {
            if (products.slice((((actualPage + 1) * productsPerPage) - productsPerPage), ((actualPage + 1) * productsPerPage)).length > 0) {
                setActualPage(actualPage + 1);
                indexOfLastProduct = (actualPage + 1) * productsPerPage;
                indexOfFirstProduct = indexOfLastProduct - productsPerPage;
                currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);
            }
        }
    }

    const resetStyleErrorFile = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredFile');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('fileMassiveProducts');
        inputElement.style.border = '';
        setFileMassiveProducts('');
        setFileNameMassiveProducts('');
    }

    const addProductsMassive = () => {
        if (fileMassiveProducts == '' || fileMassiveProducts == null) {
            let fieldRequiredElement = document.querySelector('.fieldRequiredFile');
            fieldRequiredElement.style.display = 'block';
            let inputElement = document.getElementById('fileMassiveProducts');
            inputElement.style.border = '1px solid #ff1717';
            return;
        }

        setIsButtonDisabled(true);

        const reader = new FileReader();

        reader.onload = () => {
            const base64String = reader.result.split(",")[1]; // Obtiene el string base64

            axios.post(process.env.REACT_APP_API_PRODUCTS_FILE_UPLOAD_ROUTE, base64String, {
                withCredentials: true,
                headers: {
                    Authorization: localStorage.getItem(keyLocalStorage),
                    "Content-Type": "text/plain"
                }
            })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Guardada correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                    });

                    setTimeout(() => {
                        const btnCloseModal = document.getElementById('btnCloseModalAddMassive');
                        if (btnCloseModal) {
                            btnCloseModal.click();
                        }
                    }, 1800);

                    setTimeout(() => {
                        window.location.reload();
                    }, 2100);
                })
                .catch(error => {
                    setIsButtonDisabled(false);
                    alertErrorConMensaje(error.response.data.errorMsg)
                    console.error(error);
                });
        };

        reader.readAsDataURL(fileMassiveProducts); // Lee el archivo como una URL de datos

    }

    const handleDownload = async () => {
        const {data} = await AxiosService.getWithCredentials(process.env.REACT_APP_API_PRODUCTS_FILE_DOWNLOAD_ROUTE); // Obtengo la data
        const blob = ConversorService.convertBase64ToBlob(data); // Conversion de la data
        DownloadService.downloadFile(blob, "ProductosUCODE", "xlsx"); // Genero y descargo el archivo
    }

    const handleFileChange = (event) => {
        setFileMassiveProducts(event.target.files[0]);
    };

    const deleteMassiveWithParams = () => {
        Swal.fire({
            title: '¿Estás seguro de eliminar todos los productos filtrados? ' + searchProducts.length + (searchProducts.length > 1 ? ' productos' : ' producto'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            confirmButtonColor: 'rgb(246, 139, 127)',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                let filter;
                if (nameSearching != '' && nameSearching != null && idCatSearching != '' && idCatSearching != null) {
                    console.log("ambos");
                    filter = {
                        name: nameSearching,
                        category: idCatSearching
                    };
                } else if (nameSearching != '' && nameSearching != null) {
                    console.log("name");
                    filter = {
                        name: nameSearching
                    };
                } else if (idCatSearching != '' && idCatSearching != null) {
                    console.log("idCat");
                    filter = {
                        category: idCatSearching
                    };
                    console.log(filter);
                }

                axios.delete(process.env.REACT_APP_API_PRODUCTS_ROUTE, {
                    withCredentials: true,
                    headers: {
                        Authorization: localStorage.getItem(keyLocalStorage),
                    },
                    params: filter
                })
                    .then(response => {
                        Swal.fire({
                            icon: 'success',
                            title: '¡Éxito!',
                            text: 'Eliminado correctamente.',
                            timer: 1500,
                            showConfirmButton: false,
                        });
                        setTimeout(() => {
                            window.location.reload();
                        }, 1500);
                    })
                    .catch(error => {
                        alertError();
                        console.error('Error al realizar la solicitud PUT', error);
                    });
            }
        });

    }

    const hasMoreProductsForPagination = (actualPageParam) => {
        if (searching) {
            let productsFilters;

            if (nameSearching != '' && idCatSearching != '') {
                productsFilters = products.filter(producto =>
                    producto.name.toLowerCase().includes(nameSearching.toLowerCase())
                    && producto.idCategory == idCatSearching.toString());
            } else if (nameSearching != '') {
                productsFilters = products.filter(producto =>
                    producto.name.toLowerCase().includes(nameSearching.toLowerCase()));
            } else if (idCatSearching != '') {
                productsFilters = products.filter(producto =>
                    producto.idCategory == idCatSearching.toString());
            }

            if (productsFilters && productsFilters.length > 0 &&
                productsFilters.slice((((actualPage + 1) * productsPerPage) - productsPerPage), ((actualPage + 1) * productsPerPage)).length > 0) {
                return true;
            }

        } else {
            if (products && products.length > 0 &&
                products.slice((((actualPageParam + 1) * productsPerPage) - productsPerPage), ((actualPageParam + 1) * productsPerPage)).length > 0) {
                return true;
            }
        }
        return false;
    }

    const handleSubmitSearch = (e) => {
        e.preventDefault();
        search();
    };

    const reloadFilterForEdit = () => {
        if (nameSearching != '' && idCatSearching != '') {
            const productsFilters = products.filter(producto =>
                producto.name.toLowerCase().includes(nameSearching.toLowerCase())
                && producto.idCategory == idCatSearching.toString());

            indexOfLastProduct = actualPage * productsPerPage;
            indexOfFirstProduct = indexOfLastProduct - productsPerPage;
            setSearchProducts(productsFilters.slice(indexOfFirstProduct, indexOfLastProduct));
        } else if (nameSearching != '') {
            const productsFilters = products.filter(producto =>
                producto.name.toLowerCase().includes(nameSearching.toLowerCase()));

            indexOfLastProduct = actualPage * productsPerPage;
            indexOfFirstProduct = indexOfLastProduct - productsPerPage;
            setSearchProducts(productsFilters.slice(indexOfFirstProduct, indexOfLastProduct));
        } else if (idCatSearching != '') {
            const productsFilters = products.filter(producto =>
                producto.idCategory == idCatSearching.toString());

            indexOfLastProduct = actualPage * productsPerPage;
            indexOfFirstProduct = indexOfLastProduct - productsPerPage;
            setSearchProducts(productsFilters.slice(indexOfFirstProduct, indexOfLastProduct));
        }
    }

    return (
        <div className="productsContainer">
            {(localStorage.getItem(keyLocalStorage) != null) ? (
                <>
                    <Container className="mt-3">
                        <Row>
                            <Col>
                                <h1 className="titleProducts">Productos</h1>
                            </Col>
                        </Row>
                    </Container>

                    <Container>
                        <Row className="mt-3 justify-content-center" xs={2} md={3}>
                            <Col>
                                <input type="text" className="searchTerm" placeholder="Nombre de producto"
                                       value={nameSearching}
                                       onChange={(e) => setNameSearching(e.target.value)}/>
                            </Col>
                            <Col>
                                <select
                                    className="selectFilterProd"
                                    value={idCatSearching}
                                    onChange={(e) => setIdCatSearching(e.target.value)}
                                >
                                    <option value=''>Seleccione categoría</option>
                                    {categories
                                        .map(category => (
                                            <option key={category.id} value={category.id}>{category.name}</option>))}
                                </select>
                            </Col>
                            <Col className="text-center text-md-start mt-3 mt-md-0 mb-sm-3 mb-md-0">
                                <Button id="btnSearchProduct" className="me-1" variant="primary" onClick={() => search()}>
                                    <Search/>
                                </Button>
                                <Button id="btnCleanSearchProducts" className="ms-1" variant="primary" onClick={() => cleanSearch()}>
                                    <CloseLarge/>
                                </Button>
                            </Col>
                            <div type="submit" className="searchButton" hidden='hidden' id="btnReloadSearchProduct"
                                 onClick={() => reloadFilterForEdit()}>
                            </div>
                        </Row>
                    </Container>

                    {(isLoading) ? (
                        <>
                            <div className="m-3">
                                <Table responsive className="table-striped">
                                    <thead className="tableHead">
                                    <tr>
                                        <th>ID</th>
                                        <th>Nombre</th>
                                        <th>Categoría</th>
                                        <th>Descripción</th>
                                        <th>Precio</th>
                                        <th>Estado</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td colSpan="7" className="backgroundTable text-center">
                                            <div className="loader-container-listado">
                                                <div className="loader"></div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </>
                    ) : (
                        ((searching && (searchProducts != '' && searchProducts != null)) || (products != '' && products != null)) ? (
                            <>
                                <div className="m-3">
                                    <Table responsive className="table-striped">
                                        <thead className="tableHead">
                                        <tr>
                                            <th>ID</th>
                                            <th>Nombre</th>
                                            <th>Categoría</th>
                                            <th>Descripción</th>
                                            <th>Precio</th>
                                            <th>Estado</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {(searching) ? (
                                            searchProducts
                                                .map(product => (
                                                    <tr key={product.id}>
                                                        <td className="text-alig-center fw-bold">{product.id}</td>
                                                        <td className="text-alig-center">{product.name}</td>
                                                        <td className="text-alig-center">{getCategoryNameById(product.idCategory)}</td>
                                                        <td className="text-alig-center">{product.description}</td>
                                                        <td className="text-alig-center">${formatPrice(product.price)}</td>
                                                        <td className="text-alig-center">{product.status ? "ACTIVO" : "INACTIVO"}</td>
                                                        <td width="5%">
                                                            <div className="colBtnsActionProd">
                                                                <button data-bs-toggle="modal"
                                                                        data-bs-target="#modaleEditProduct"
                                                                        className="btn btnEdit"><RequestQuote
                                                                    className="btnEdit" size="24"
                                                                    onClick={() => editProduct(product)}/></button>
                                                                {product.status ? (
                                                                    <button className="btn btnActiveInactive"
                                                                            onClick={() => disableProd(product)}><View
                                                                        className="btnActiveInactive" size="24"/>
                                                                    </button>
                                                                ) : (
                                                                    <button className="btn btnActiveInactive"
                                                                            onClick={() => enableProd(product)}><ViewOff
                                                                        className="btnActiveInactive" size="24"/>
                                                                    </button>
                                                                )}
                                                                <button className="btn btnDelete"
                                                                        onClick={() => deleteProduct(product)}><TrashCan
                                                                    className="btnDelete" size="24"/></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                        ) : (
                                            currentProducts
                                                .map(product => (
                                                    <tr key={product.id}>
                                                        <td className="text-alig-center fw-bold">{product.id}</td>
                                                        <td className="text-alig-center">{product.name}</td>
                                                        <td className="text-alig-center">{getCategoryNameById(product.idCategory)}</td>
                                                        <td className="text-alig-center">{product.description}</td>
                                                        <td className="text-alig-center">${formatPrice(product.price)}</td>
                                                        <td className="text-alig-center">{product.status ? "ACTIVO" : "INACTIVO"}</td>
                                                        <td width="5%">
                                                            <div className="colBtnsActionProd">
                                                                <button data-bs-toggle="modal"
                                                                        data-bs-target="#modaleEditProduct"
                                                                        className="btn btnEdit"><RequestQuote
                                                                    className="btnEdit" size="24"
                                                                    onClick={() => editProduct(product)}/></button>
                                                                {product.status ? (
                                                                    <button className="btn btnActiveInactive"
                                                                            onClick={() => disableProd(product)}><View
                                                                        className="btnActiveInactive" size="24"/>
                                                                    </button>
                                                                ) : (
                                                                    <button className="btn btnActiveInactive"
                                                                            onClick={() => enableProd(product)}><ViewOff
                                                                        className="btnActiveInactive" size="24"/>
                                                                    </button>
                                                                )}
                                                                <button className="btn btnDelete"
                                                                        onClick={() => deleteProduct(product)}><TrashCan
                                                                    className="btnDelete" size="24"/></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                        )}

                                        </tbody>
                                    </Table>

                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination">
                                            <li className="page-item">
                                                <a className="page-link colorPrincipal" href="#" aria-label="Previous"
                                                   onClick={() => {
                                                       previewPage()
                                                   }}>
                                                    <span aria-hidden="true">&laquo;</span>
                                                </a>
                                            </li>
                                            {actualPage > 1 && <li className="page-item"><a
                                                className="page-link colorPrincipal">{actualPage - 1}</a></li>}
                                            <li className="page-item"><a className="page-link colorPrincipal"
                                                                         style={{backgroundColor: 'rgb(255 230 230)'}}>{actualPage}</a>
                                            </li>
                                            {hasMoreProductsForPagination(actualPage) && <li className="page-item"><a
                                                className="page-link colorPrincipal">{actualPage + 1}</a></li>}
                                            <li className="page-item">
                                                <a className="page-link colorPrincipal" href="#" aria-label="Next"
                                                   onClick={() => {
                                                       nextPage()
                                                   }}>
                                                    <span aria-hidden="true">&raquo;</span>
                                                </a>
                                            </li>
                                        </ul>
                                        {(searching) ? (
                                            <p>Productos filtrados: {cantProductsFiltered} </p>
                                        ) : (
                                            <p>Total de productos: {products.length}</p>
                                        )}
                                    </nav>

                                    {(searching) &&
                                        <div className="divDeleteMassive">
                                            <button className="btnAddCategory btn"
                                                    disabled={searchProducts.length > 0 ? false : true} style={{
                                                backgroundColor: "rgb(235, 120, 107)",
                                                marginBottom: "5%",
                                                marginLeft: '3%',
                                                color: "#ffffff",
                                                width: '11%'
                                            }} onClick={deleteMassiveWithParams}>Eliminar productos
                                            </button>
                                        </div>
                                    }

                                </div>
                            </>
                        ) : (
                            <>
                            <div className="m-3">
                                    <Table responsive className="table-striped">
                                        <thead className="tableHead">
                                        <tr>
                                            <th>ID</th>
                                            <th>Nombre</th>
                                            <th>Categoría</th>
                                            <th>Descripción</th>
                                            <th>Precio</th>
                                            <th>Estado</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td colSpan="7" className="backgroundTable text-center">
                                                Aún no hay Productos
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </>
                        )
                    )}

                    <Container className="mt-3">
                        <Row>
                            <Col className="text-alig-center">
                                <Button variant="primary" className="me-1" data-bs-toggle="modal" data-bs-target="#modaleAddProduct">Agregar</Button>
                                <Button variant="primary" className="ms-1" data-bs-toggle="modal" data-bs-target="#modaleAddProductMassive">Carga Masiva</Button>
                            </Col>
                        </Row>
                    </Container>

                    <div className="modal fade modal-lg" id="modaleAddProductMassive" data-bs-backdrop="static"
                         data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modaleAddProductMassiveLabel"
                         aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="modaleAddProductMassiveLabel"
                                        className="titleModalCreateCategory">Carga masiva de productos</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                            onClick={() => {
                                                resetStyleErrorFile()
                                            }}></button>
                                </div>
                                <div className="modal-body">
                                    <button className="btn" style={{backgroundColor: '#C65A4E', color: 'white'}}
                                            onClick={handleDownload}>
                                        Descargar Template
                                    </button>
                                    <div style={{marginTop: '5%'}}>
                                        <label htmlFor="fileMassiveProducts" className="col-form-label">Carga archivo excel con
                                            productos:</label>
                                        <input type="file" className="form-control" id="fileMassiveProducts"
                                               value={fileNameMassiveProducts} onChange={(e) => {
                                            setFileNameMassiveProducts(e.target.value);
                                            handleFileChange(e);
                                        }}/>
                                        <div className="fieldRequiredFile" display='none'>El archivo es obligatorio
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                        <button id="btnCloseModalAddMassive" type="button" className="btn btn-secondary"
                                                data-bs-dismiss="modal" onClick={() => {
                                                    resetStyleErrorFile();
                                                }}>Cerrar
                                        </button>
                                        <button type="button" className="btn btn-primary"
                                                onClick={addProductsMassive}
                                                disabled={isButtonDisabled}>
                                            {isButtonDisabled ? "Guardando..." : "Guardar"}
                                        </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade modal-lg" id="modaleAddProduct" data-bs-backdrop="static"
                         data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modaleAddProductLabel"
                         aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="modaleAddProductLabel"
                                        className="titleModalCreateProduct">Nuevo producto</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                            onClick={() => resetValuesAndStyleErrors()}></button>
                                </div>
                                <div className="modal-body">
                                    <div className="mb-3">
                                        <label htmlFor="nombre" className="col-form-label">Nombre:</label>
                                        <input type="text" className="form-control" id="nombre" value={productName}
                                               onChange={(e) => {
                                                   const productNameIng = e.target.value;
                                                   if (productNameIng.length <= 100) {
                                                       setProductName(productNameIng);
                                                   }
                                                   cleanErrorName();
                                               }}/>
                                        <div className="fieldRequiredName">Campo obligatorio</div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="description" className="col-form-label">Descripción:</label>
                                        <input type="text" className="form-control" id="description"
                                               value={productDescription} onChange={(e) => {
                                            const productDescriptionIng = e.target.value;
                                            if (productDescriptionIng.length <= 255) {
                                                setProductDescription(productDescriptionIng);
                                            }
                                            cleanErrorDesc();
                                        }}/>
                                        <div className="fieldRequiredDescription">Campo obligatorio</div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="nombre" className="col-form-label">Categoría:</label>
                                        <select id="selector" className="form-control" value={selectedCategory}
                                                onChange={handleSelectCategory}>
                                            <option value=''>Seleccionar categoría</option>
                                            {categoriesActive
                                                .map(category => (
                                                    <option value={category.id}>{category.name}</option>
                                                ))}
                                        </select>
                                        <div className="fieldRequiredCategory">Campo obligatorio</div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="price" className="col-form-label">Precio:</label>
                                        <div className="price-input">
                                            <p className="dollar-sign"> $ </p>
                                            <input type="number" className="form-control" id="price" value={productPrice}
                                                   onChange={(e) => {
                                                       const productPriceIng = e.target.value;
                                                       const hasDecimal = productPriceIng.includes(".");
                                                       if (hasDecimal) {
                                                           if (productPriceIng.length <= 20) {
                                                               setProductPrice(productPriceIng);
                                                           }
                                                       } else {
                                                           if (productPriceIng.length <= 17) {
                                                               setProductPrice(productPriceIng);
                                                           }
                                                       }
                                                       cleanErrorPrice();
                                                   }}/>
                                        </div>
                                        <div className="fieldRequiredPrice">Campo obligatorio</div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button id="btnCloseModalAdd" type="button" className="btn btn-secondary"
                                            data-bs-dismiss="modal" onClick={() => resetValuesAndStyleErrors()}>Cerrar
                                    </button>
                                    <button type="button" className="btn btn-primary"
                                            onClick={() => saveProduct()}>Guardar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade modal-lg" id="modaleEditProduct" data-bs-backdrop="static"
                         data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modaleEditProductLabel"
                         aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="modaleEditProductLabel"
                                        className="titleModalEditProduct">Editar producto</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                            onClick={() => resetStyleErrorsEdit()}></button>
                                </div>
                                <div className="modal-body">
                                    <div className="mb-3">
                                        <label htmlFor="nombreEdit" className="col-form-label">Nombre:</label>
                                        <input type="text" className="form-control" id="nombreEdit" value={productNameEdit}
                                               onChange={(e) => {
                                                   const productNameEditIng = e.target.value;
                                                   if (productNameEditIng.length <= 100) {
                                                       setProductNameEdit(productNameEditIng);
                                                   }
                                                   cleanErrorNameEdit();
                                               }}/>
                                        <div className="fieldRequiredNameEdit">Campo obligatorio</div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="descriptionEdit" className="col-form-label">Descripción:</label>
                                        <input type="text" className="form-control" id="descriptionEdit"
                                               value={productDescriptionEdit} onChange={(e) => {
                                            const productDescriptionEditIng = e.target.value;
                                            if (productDescriptionEditIng.length <= 255) {
                                                setProductDescriptionEdit(productDescriptionEditIng);
                                            }
                                            cleanErrorDescEdit();
                                        }}/>
                                        <div className="fieldRequiredDescriptionEdit">Campo obligatorio</div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="category" className="col-form-label">Categoría:</label>
                                        <select id="selectorEdit" className="form-control" value={selectedCategoryEdit}
                                                onChange={handleSelectCategoryEdit}>
                                            {categoriesActive
                                                .map(category => (
                                                    <option value={category.id}>{category.name}</option>
                                                ))}
                                        </select>
                                        <div className="fieldRequiredCategoryEdit">Campo obligatorio</div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="priceEdit" className="col-form-label">Precio:</label>
                                        <div className="price-input">
                                            <p className="dollar-sign"> $ </p>
                                            <input type="number" className="form-control" id="priceEdit"
                                                   value={productPriceEdit} onChange={(e) => {
                                                const productPriceEditIng = e.target.value;
                                                const hasDecimal = productPriceEditIng.includes(".");
                                                if (hasDecimal) {
                                                    if (productPriceEditIng.length <= 20) {
                                                        setProductPriceEdit(productPriceEditIng);
                                                    }
                                                } else {
                                                    if (productPriceEditIng.length <= 17) {
                                                        setProductPriceEdit(productPriceEditIng);
                                                    }
                                                }
                                                cleanErrorPriceEdit();
                                            }}/>
                                        </div>
                                        <div className="fieldRequiredPriceEdit">Campo obligatorio</div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button id="btnCloseModalEdit" type="button" className="btn btn-secondary"
                                            data-bs-dismiss="modal" onClick={() => resetStyleErrorsEdit()}>Cerrar
                                    </button>
                                    <button type="button" className="btn btn-primary"
                                            onClick={() => saveEditproduct()}>Guardar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div id="mainNotFound">
                    <div className="fof">
                        <h1 className='h1Error404'>Error 404</h1>
                    </div>
                </div>
            )}

        </div>
    );
}