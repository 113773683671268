import {createContext, useContext, useState} from "react";

const ShopContext = createContext();

const ShopProvider = ({children}) => {

    const [activeOrders, setActiveOrders] = useState(false);
    const [activeDelivery, setActiveDelivery] = useState(false);
    const [deliveryPrice, setDeliveryPrice] = useState(0);

    const toggleActiveOrders = () => {
        setActiveOrders(!activeOrders);
    }

    const toggleActiveDelivery = () => {
        setActiveDelivery(!activeDelivery)
    }

    const changeDeliveryPrice = (deliveryPrice) => {
        setDeliveryPrice(deliveryPrice);
    }

    return (
        <ShopContext.Provider
            value={{
                activeOrders,
                toggleActiveOrders,
                activeDelivery,
                toggleActiveDelivery,
                deliveryPrice,
                changeDeliveryPrice
            }}>
            {children}
        </ShopContext.Provider>
    );

}

export {ShopProvider, ShopContext};

export function useShop() {
    return useContext(ShopContext);
}

/*
*
*  Para usar el contexto simplemente tengo que agregar
*  const { activeOrders, toggleActiveOrders } = useShop();
*  O las funciones que querramos de este contexto
*  En el componente que las quiera usar y lo consumimos
*  como si fuese un estado mas, pero afectara a toda la aplicacion
* 
* */