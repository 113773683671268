import { Link } from 'react-router-dom';

const HomeCardLink = ({ to, title, subtitle }) => {
  return (
    <div className='cardItem'>
        <Link to={to} target='_black' className="cardItemLink">
            <div className='cardTextContainer'>
                <p className='cardTitle mb-0'>{title}</p>
                <p className='cardSubtitle mb-0'>{subtitle}</p>
            </div>
        </Link>
    </div>
  )
}

export default HomeCardLink;