import Menu from "../components/Menu/Menu";
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios'; 

export default function SocialMedia(){

    const keyLocalStorage = 'authToken';

    const [instagram, setInstagram] = useState();
    const [facebook, setFacebook] = useState();
    const [twitter, setTwitter] = useState();

    useEffect(() => {
        document.title = 'QReate - Redes sociales';

        if(localStorage.getItem(keyLocalStorage) != null){
                axios.get(process.env.REACT_APP_API_SOCIALMEDIA_ROUTE, {
                withCredentials: true,
                params: {
                    status: true,
                },
                headers: {
                    Authorization: localStorage.getItem(keyLocalStorage),
                }
            })
            .then(response => {
                getValueRedInstagram(response.data);
                getValueRedFacebook(response.data);
                getValueRedTwitter(response.data);
            })
        }
    }, []);

    const getValueRedInstagram = (redes) =>{
        let instagram = redes && redes.find(red => red.socialMediaType === "INSTAGRAM");
        if(instagram != null){
            setInstagram(instagram.socialMediaLink);
        }
    }

    const getValueRedFacebook = (redes) =>{
        let facebook = redes && redes.find(red => red.socialMediaType === "FACEBOOK");
        if(facebook != null){
            setFacebook(facebook.socialMediaLink);
        }
    }

    const getValueRedTwitter = (redes) =>{
        let twitter = redes && redes.find(red => red.socialMediaType === "TWITTER");
        if(twitter != null){
            setTwitter(twitter.socialMediaLink);
        }
    }

    function alertError(){
        Swal.fire({
            title: 'Upsss',
            text: 'Ocurrió un error, vuelve a intentar',
            icon: 'error',
            timer: 1500,
            showConfirmButton: false,
        });
    }

    const saveInstagram = () =>{
        axios.get(process.env.REACT_APP_API_SOCIALMEDIA_ROUTE, {
            withCredentials: true,
            params: {
                socialMediaType: "INSTAGRAM",
            },
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            }
        })
        .then(response => {
            if(response.data[0] != null){
                let redSocial = {
                    socialMediaType: "INSTAGRAM",
                    socialMediaLink: instagram,
                    status: true
                }
    
                axios.put(process.env.REACT_APP_API_SOCIALMEDIA_ROUTE + '/' + response.data[0].id, redSocial, {
                    withCredentials: true,
                    headers: {
                      Authorization: localStorage.getItem(keyLocalStorage),
                    }
                  })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Guardado correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                    });
                })
                .catch(error => {
                    alertError();
                    console.error(error)});
            }else{
                let newRedSocial = {
                    socialMediaType: "INSTAGRAM",
                    socialMediaLink: instagram
                }
                axios.post(process.env.REACT_APP_API_SOCIALMEDIA_ROUTE, newRedSocial, {
                    withCredentials: true,
                    headers: {
                      Authorization: localStorage.getItem(keyLocalStorage),
                    }
                  })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Guardado correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                    });
                })
                .catch(error => {
                    alertError();
                    console.error(error)});
            }
        }) 
    }

    const saveFacebook = () =>{
        axios.get(process.env.REACT_APP_API_SOCIALMEDIA_ROUTE, {
            withCredentials: true,
            params: {
                socialMediaType: "FACEBOOK",
            },
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            }
        })
        .then(response => {
            if(response.data[0] != null){
                let redSocial = {
                    socialMediaType: "FACEBOOK",
                    socialMediaLink: facebook,
                    status: response.data[0].status
                }
    
                axios.put(process.env.REACT_APP_API_SOCIALMEDIA_ROUTE + '/' + response.data[0].id, redSocial,{
                    withCredentials: true,
                    headers: {
                      Authorization: localStorage.getItem(keyLocalStorage),
                    }
                  })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Guardado correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                      });
                })
                .catch(error => {
                    alertError();
                    console.error(error)});
            }else{
                let newRedSocial = {
                    socialMediaType: "FACEBOOK",
                    socialMediaLink: facebook
                }
                axios.post(process.env.REACT_APP_API_SOCIALMEDIA_ROUTE, newRedSocial, {
                    withCredentials: true,
                    headers: {
                      Authorization: localStorage.getItem(keyLocalStorage),
                    }
                  })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Guardado correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                      });
                })
                .catch(error => {
                    alertError();
                    console.error(error)});
            }
        }) 
    }

    const saveTwitter = () =>{
        axios.get(process.env.REACT_APP_API_SOCIALMEDIA_ROUTE, {
            withCredentials: true,
            params: {
                socialMediaType: "TWITTER",
            },
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            }
        })
        .then(response => {
            if(response.data[0] != null){
                let redSocial = {
                    socialMediaType: "TWITTER",
                    socialMediaLink: twitter,
                    status: response.data[0].status
                }
    
                axios.put(process.env.REACT_APP_API_SOCIALMEDIA_ROUTE + '/' + response.data[0].id, redSocial, {
                    withCredentials: true,
                    headers: {
                      Authorization: localStorage.getItem(keyLocalStorage),
                    }
                  })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Guardado correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                      });
                })
                .catch(error => {
                    alertError();
                    console.error(error)});
            }else{
                let newRedSocial = {
                    socialMediaType: "TWITTER",
                    socialMediaLink: twitter
                }
                axios.post(process.env.REACT_APP_API_SOCIALMEDIA_ROUTE, newRedSocial, {
                    withCredentials: true,
                    headers: {
                      Authorization: localStorage.getItem(keyLocalStorage),
                    }
                  })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Guardado correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                      });
                })
                .catch(error => {
                    alertError();
                    console.error(error)});
            }
        }) 
    }

    return(
    <div>
        {(localStorage.getItem(keyLocalStorage) != null) ? (
            <>

                <h1 className="titleRRSS">Redes Sociales</h1>

                <div className="divRRSS">

                    <div class="mb-3">
                        <label for="nombre" class="col-form-label labelsRedes">Instagram</label>
                        <input placeholder="Link del perfil de instagram" type="text" class="form-control" id="nombre" value={instagram} onChange={(e) => {
                            const instagramIng = e.target.value;
                            if(instagramIng.length <= 255){
                                setInstagram(instagramIng);
                            }}}/>
                    </div>

                    <div className="divSaveRedes">
                        <button className="btnSaveRedes btn" style={{backgroundColor: "rgb(235, 120, 107)", color: "#ffffff"}} onClick={() => {
                            if(instagram != null){
                                saveInstagram();
                            }}}>Guardar</button>
                    </div>

                    <br/>

                    <div class="mb-3">
                        <label for="nombre" class="col-form-label labelsRedes">Facebook</label>
                        <input placeholder="Link del perfil de facebook" type="text" class="form-control" id="nombre" value={facebook} onChange={(e) => {
                            const facebookIng = e.target.value;
                            if(facebookIng.length <= 255){
                                setFacebook(facebookIng);
                            }}}/>
                    </div>

                    <div className="divSaveRedes">
                        <button className="btnSaveRedes btn" style={{backgroundColor: "rgb(235, 120, 107)", color: "#ffffff"}} onClick={() => {
                            if(facebook != null){
                                saveFacebook();
                            }}}>Guardar</button>
                    </div>

                    <br/>

                    <div class="mb-3">
                        <label for="nombre" class="col-form-label labelsRedes">Twitter</label>
                        <input placeholder="Link del perfil de twitter" type="text" class="form-control" id="nombre" value={twitter} onChange={(e) => {
                            const twitterIng = e.target.value;
                            if(twitterIng.length <= 255){
                                setTwitter(twitterIng);
                            }}}/>
                    </div>

                    <div className="divSaveRedes">
                        <button className="btnSaveRedes btn" style={{backgroundColor: "rgb(235, 120, 107)", color: "#ffffff"}} onClick={() => {
                            if(twitter != null){
                                saveTwitter();
                            }}}>Guardar</button>
                    </div>
                </div>
            </>
        ) : (
            <div id="mainNotFound">
        <div className="fof">
          <h1 className='h1Error404'>Error 404</h1>
        </div>
      </div>
        )}
        
    </div>
    );
}